<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<el-breadcrumb separator="/"><el-breadcrumb-item>红包兑换</el-breadcrumb-item></el-breadcrumb>
			</div>

			<div class="apply-withdrawal" v-loading="loading">
				<div class="apply-wrap">
					<div class="apply-account-money demo-input-suffix">
						<span class="ns-width">红包兑换码：</span>
						<el-input type="text" placeholder="请输入兑换码" v-model="red_code" :disabled="bankAccountInfo.withdraw_type == 'wechatpay'">
							<template slot="prepend"></template>
						</el-input>
					</div>
					<div class="apply-account-btn">
						<span class="ns-width"></span>
						<el-button
							type="primary"
							size="medium"
							@click="leadRedpacket"
							:class="{ disabled: red_code == '' || red_code == 0 }"
							:disabled="bankAccountInfo.withdraw_type == 'wechatpay'"
						>
							兑换
						</el-button>
						<!-- <router-link to="/member/withdrawal">提现记录</router-link> -->
					</div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
  import { withdrawInfo, accountInfo, withdraw } from "@/api/member/account";
  import { redInfo,leadRedpacket } from "@/api/member/my_red_list.js";



  export default {
      name: "apply_withdrawal",
      components: {},
      data: () => {
          return {
              withdrawInfo: {
              	config: {
              		is_use: 0,
              		min: 1,
              		rate: 0
              	},
              	member_info: {
              		balance_money: 0,
              		balance_withdraw: 0,
              		balance_withdraw_apply: 0
              	}
              },
              bankAccountInfo: {},
              withdrawMoney: '',
              isSub: false,
		loading: true,
		yes: true,
		red_code:''
          }
      },
filters: {
	/**
	 * 金额格式化输出
	 * @param {Object} money
	 */
	moneyFormat(money) {
		return parseFloat(money).toFixed(2);
	}
},
      created() {
	this.loading = false
      },
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
      methods: {


	leadRedpacket(){
		if (this.red_code=='') {
			this.$message({
				message: "请输入兑换码",
				type: "warning"
			})
			return;
		}
		
		redInfo({
			code: this.red_code,
			lead_type: 1 
		}).then(res => {
			if (res.code >= 0) {
				console.log(res.data.record_id);
				leadRedpacket({
					record_id: res.data.record_id,
					lead_type: 1 
				}).then(res => {
					if (res.code >= 0) {
						this.$message({
							message: '兑换成功！',
							type: 'success',
							duration: 2000,
							onClose: () => {
								this.$router.push('/member/my_red_list');
							}
						});
					} else {
						this.isSub = false;
						this.$message({
							message: res.message,
							type: "warning"
						});
					}
				}).catch(err => {
					this.isSub = false;
					this.$message({
						message: err.message,
						type: "warning"
					});
				})
			} else {
				this.isSub = false;
				this.$message({
					message: res.message,
					type: "warning"
				});
			}
		}).catch(err => {
			this.isSub = false;
			this.$message({
				message: err.message,
				type: "warning"
			});
		})
		
		
		
		
		
		
		
	},


	withdraw() {
		if (!this.bankAccountInfo.withdraw_type) {
			this.$message({
				message: "请先添加提现方式",
				type: "warning"
			})
			return;
		}
		if (this.withdrawMoney == '' || this.withdrawMoney == 0 || isNaN(parseFloat(this.withdrawMoney))) {
			this.$message({
				message: '请输入提现金额',
				type: "warning"
			});
			return false;
		}
		if (parseFloat(this.withdrawMoney) > parseFloat(this.withdrawInfo.member_info.balance_money)) {
			this.$message({
				message: '提现金额超出可提现金额',
				type: "warning"
			});
			return false;
		}
		if (parseFloat(this.withdrawMoney) < parseFloat(this.withdrawInfo.config.min)) {
			this.$message({
				message: '提现金额小于最低提现金额',
				type: "warning"
			});
			return false;
		}

		if (this.isSub) return;
		this.isSub = true;

		withdraw({
			apply_money: this.withdrawMoney,
			transfer_type: this.bankAccountInfo.withdraw_type, //转账提现类型
			realname: this.bankAccountInfo.realname,
			mobile: this.bankAccountInfo.mobile,
			bank_name: this.bankAccountInfo.branch_bank_name,
			account_number: this.bankAccountInfo.bank_account
		}).then(res => {
			if (res.code >= 0) {
				this.$message({
					message: '提现申请成功！',
					type: 'success',
					duration: 2000,
					onClose: () => {
						this.$router.push('/member/withdrawal');
					}
				});
			} else {
				this.isSub = false;
				this.$message({
					message: res.message,
					type: "warning"
				});
			}
		}).catch(err => {
			this.isSub = false;
			this.$message({
				message: err.message,
				type: "warning"
			});
		})
	},
      }
  }
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #ffffff;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.apply-withdrawal {
	width: 100%;
	background-color: #ffffff;

	.apply-wrap {
		display: inline-block;
		width: 500px;
		box-sizing: border-box;

		.apply-account {
			display: flex;
			align-items: center;
			cursor: pointer;

			.apply-account-info {
				display: flex;
				align-items: center;

				span {
					margin-right: 5px;
				}

				.el-image {
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}
			}
		}

		.apply-account-money {
			display: flex;
			align-items: center;
			margin-top: 30px;

			span {
				flex-shrink: 0;
			}
		}

		.apply-account-desc {
			margin-top: 30px;
			text-align: left;

			p:first-child {
				span.balance {
					margin-right: 10px;
				}
				span:nth-child(4) {
					color: $base-color;
					cursor: pointer;
				}
			}

			p:nth-child(2) {
				color: #999999;
			}
		}

		.apply-account-btn {
			margin-top: 30px;
			.el-button {
				margin-right: 20px;
			}
		}
	}

	.ns-width {
		display: inline-block;
		width: 115px;
		text-align: right;
	}
}
</style>
